<template>
  <div>
    <el-data-table ref="table" v-bind="tableConfig" :columns="columns">
    </el-data-table>

    <el-dialog :title="title" :visible.sync="dialogFormVisible" :close-on-press-escape="false" :close-on-click-modal="false"  :before-close="closeDialog">
      <el-form :model="form" :rules="rules" ref="ruleUser">
                    <el-form-item label='序号' prop='id' :label-width="formLabelWidth" v-if="false">
              <el-input placeholder='请输入序号' clearable autocomplete="off" v-model='form.id' size="small"  />
            </el-form-item>
            <el-form-item label='订单号' prop='orderid' :label-width="formLabelWidth">
              <el-input placeholder='请输入订单号' clearable autocomplete="off" v-model='form.orderid' size="small"  />
            </el-form-item>
            <el-form-item label='用户id' prop='userid' :label-width="formLabelWidth">
              <el-input placeholder='请输入用户id' clearable autocomplete="off" v-model='form.userid' size="small"  />
            </el-form-item>
            <el-form-item label='用户邮箱' prop='useremail' :label-width="formLabelWidth">
              <el-input placeholder='请输入用户邮箱' clearable autocomplete="off" v-model='form.useremail' size="small"  />
            </el-form-item>
            <el-form-item label='商品id' prop='spuid' :label-width="formLabelWidth">
              <el-input placeholder='请输入商品id' clearable autocomplete="off" v-model='form.spuid' size="small"  />
            </el-form-item>
            <el-form-item label='车辆id' prop='carid' :label-width="formLabelWidth">
              <el-input placeholder='请输入车辆id' clearable autocomplete="off" v-model='form.carid' size="small"  />
            </el-form-item>
            <el-form-item label='车票id' prop='ticketid' :label-width="formLabelWidth">
              <el-input placeholder='请输入车票id' clearable autocomplete="off" v-model='form.ticketid' size="small"  />
            </el-form-item>
            <el-form-item label='退款状态' prop='refundstatus' :label-width="formLabelWidth">
              <el-input placeholder='请输入退款状态' clearable autocomplete="off" v-model='form.refundstatus' size="small"  />
            </el-form-item>
            <el-form-item label='支付状态' prop='paystatus' :label-width="formLabelWidth">
              <el-input placeholder='请输入支付状态' clearable autocomplete="off" v-model='form.paystatus' size="small"  />
            </el-form-item>
            <el-form-item label='国家代码' prop='countrycode' :label-width="formLabelWidth">
              <el-input placeholder='请输入国家代码' clearable autocomplete="off" v-model='form.countrycode' size="small"  />
            </el-form-item>
            <el-form-item label='国家名称' prop='countryname' :label-width="formLabelWidth">
              <el-input placeholder='请输入国家名称' clearable autocomplete="off" v-model='form.countryname' size="small"  />
            </el-form-item>
            <el-form-item label='支付方式' prop='paytype' :label-width="formLabelWidth">
              <el-input placeholder='请输入支付方式' clearable autocomplete="off" v-model='form.paytype' size="small"  />
            </el-form-item>
            <el-form-item label='退款金额' prop='refundamount' :label-width="formLabelWidth">
              <el-input placeholder='请输入退款金额' clearable autocomplete="off" v-model='form.refundamount' size="small"  />
            </el-form-item>
            <el-form-item label='剩余金额' prop='surplus' :label-width="formLabelWidth">
              <el-input placeholder='请输入剩余金额' clearable autocomplete="off" v-model='form.surplus' size="small"  />
            </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDialog()">取 消</el-button>
        <el-button type="primary" @click="submitUser">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { insertOrderrefund,updateOrderrefund,deleteOrderrefund,bacthDelOrderrefund,getGoodsSpuAll,orderrefundPass,orderrefundClose } from "@/request/requests";
  import { MessageSuccess,MessageError,MessageInfo } from "@/util/util"
  export default {
    name: "user",
    data(){
        const refundstatusOptions=[
            {
                value:"1",
                label:"提交退款"
            },
            {
                value:"2",
                label:"退款通过"
            },
            {
                value:"3",
                label:"退款关闭"
            },
        ]
      const columns = [
          {type: 'selection'},
          {prop: 'id',label: '序号'},
          {prop: 'orderid',label: '订单号'},
          {prop: 'userid',label: '用户id'},
          {prop: 'useremail',label: '用户邮箱'},
          {prop: 'spuid',label: '商品id'},
          {prop: 'ticket.goodsSpu.goodsname',label: '商品名称'},
          {prop: 'carid',label: '车辆id'},
          {prop: 'ticketid',label: '车票id'},
          {prop: 'refundstatus',label: '退款状态',formatter: row => this.statusState(row)},
          // {prop: 'paystatus',label: '支付状态'},
          {prop: 'countrycode',label: '国家代码'},
          {prop: 'countryname',label: '国家名称'},
          {prop: 'paytype',label: '支付方式'},
          {prop: 'refundamount',label: '退款金额'},
          {prop: 'surplus',label: '剩余金额'},
          {prop: 'createTime',label: '创建时间'},
          {prop: 'updateTime',label: '更新时间'},
      ]
      const searchForm = [
          {
              type: 'date-picker',
              id: 'date',
              label: '时间筛选',
              el: {
                  type: 'daterange',
                  valueFormat: 'yyyy-MM-dd'
              },
              inputFormat: (row) => {
                  if (row.startdate && row.enddate) {
                      return [row.startdate, row.enddate]
                  }
              },
              outputFormat: (val) => {
                  if (!val) {
                      return ""
                  }
                  return {
                      startdate: val[0],
                      enddate: val[1]
                  }
              }
          },
          {
              type: 'select',
              id: 'spuid',
              label: '商品名称',
              el: {placeholder: '请输入商品名称', clearable: true},
              options:this.spuList
          },
          {
            type: 'input',
            id: 'orderid',
            label: '订单号',
            el: {placeholder: '请输入订单号', clearable: true},
          },
          {
            type: 'input',
            id: 'userid',
            label: '用户id',
            el: {placeholder: '请输入用户id', clearable: true},
          },
          {
            type: 'input',
            id: 'useremail',
            label: '用户邮箱',
            el: {placeholder: '请输入用户邮箱', clearable: true},
          },
          {
            type: 'input',
            id: 'carid',
            label: '车辆id',
            el: {placeholder: '请输入车辆id', clearable: true},
          },
          {
            type: 'input',
            id: 'ticketid',
            label: '车票id',
            el: {placeholder: '请输入车票id', clearable: true},
          },
          {
            type: 'select',
            id: 'refundstatus',
            label: '退款状态',
            el: {placeholder: '请输入退款状态', clearable: true},
            options:refundstatusOptions
          },
          // {
          //   type: 'input',
          //   id: 'paystatus',
          //   label: '支付状态',
          //   el: {placeholder: '请输入支付状态', clearable: true},
          // },
          {
            type: 'input',
            id: 'countrycode',
            label: '国家代码',
            el: {placeholder: '请输入国家代码', clearable: true},
          },
          {
            type: 'input',
            id: 'countryname',
            label: '国家名称',
            el: {placeholder: '请输入国家名称', clearable: true},
          },
          {
            type: 'input',
            id: 'paytype',
            label: '支付方式',
            el: {placeholder: '请输入支付方式', clearable: true},
          },
      ]
      return {
        columns,
        refundstatusOptions,
        userinfo:{},
        spuList:[],
        tableConfig: {
          searchForm,
          url: 'orderrefund/list',
          dataPath: 'data.list',
          totalPath: 'data.total',
          paginationSize:10,
          extraButtons: [
            {
              type: 'primary',
              text: '编辑',
              icon: 'el-icon-edit-outline',
              show:()=>{return this.hasRole('orderrefund:update')},
              atClick: async row => {
                this.handleEdit(row)
                return false
              },
            },
            {
              type: 'primary',
              text: '删除',
              icon: 'el-icon-delete',
              show:()=>{return this.hasRole('orderrefund:delete')},
              atClick: async row => {
                this.handleDelete(row)
                return false
              },
            },
              {
                  type: 'primary',
                  text: '通过',
                  show:()=>{return this.hasRole('orderrefund:pass')},
                  atClick: async row => {
                      this.handlePass(row)
                      return false
                  },
              },
              {
                  type: 'primary',
                  text: '关闭',
                  show:()=>{return this.hasRole('orderrefund:close')},
                  atClick: async row => {
                      this.handleRefundClose(row)
                      return false
                  },
              },
          ],
          headerButtons: [
            {
              text: '新增',
              type: 'primary',
              show:()=>{return this.hasRole('orderrefund:insert')},
              atClick: async () => {
                this.handleInsert()
                return false
              },
            },
            {
              text: '批量删除',
              type: 'primary',
              show:()=>{return this.hasRole('orderrefund:batchDelete')},
              disabled: selected => selected.length < 1,
              atClick: selected => {
                let ids = selected.map(item => item.id)
                this.Batch('delete', ids)
                return false
              },
            },
          ],
          hasDelete: false,
          hasEdit: false,
          hasOperation: true,
          hasNew: false,
          operationAttrs: {
            width: '150px',
            fixed: 'right',
          },
        },
        form:this.initForm(),
        title:"",
        type:"",
        dialogFormVisible:false,
        formLabelWidth:"100px",
        rules: {
            id :[
              {required: true, message: '请输入序号', trigger: 'blur'},],
            orderid :[
              {required: true, message: '请输入订单号', trigger: 'blur'},],
            userid :[
              {required: true, message: '请输入用户id', trigger: 'blur'},],
            useremail :[
              {required: true, message: '请输入用户邮箱', trigger: 'blur'},],
            spuid :[
              {required: true, message: '请输入商品id', trigger: 'blur'},],
            carid :[
              {required: true, message: '请输入车辆id', trigger: 'blur'},],
            ticketid :[
              {required: true, message: '请输入车票id', trigger: 'blur'},],
            refundstatus :[
              {required: true, message: '请输入退款状态', trigger: 'blur'},],
            paystatus :[
              {required: true, message: '请输入支付状态', trigger: 'blur'},],
            countrycode :[
              {required: true, message: '请输入国家代码', trigger: 'blur'},],
            countryname :[
              {required: true, message: '请输入国家名称', trigger: 'blur'},],
            paytype :[
              {required: true, message: '请输入支付方式', trigger: 'blur'},],
            refundamount :[
              {required: true, message: '请输入退款金额', trigger: 'blur'},],
            surplus :[
              {required: true, message: '请输入剩余金额', trigger: 'blur'},],
            createTime :[
              {required: true, message: '请输入创建时间', trigger: 'blur'},],
            updateTime :[
              {required: true, message: '请输入更新时间', trigger: 'blur'},],
            ext1 :[
              {required: true, message: '请输入扩展字段', trigger: 'blur'},],
            ext2 :[
              {required: true, message: '请输入扩展字段', trigger: 'blur'},],
            ext3 :[
              {required: true, message: '请输入扩展字段', trigger: 'blur'},],
            ext4 :[
              {required: true, message: '请输入扩展字段', trigger: 'blur'},],
        },
      }
    },
    methods:{
        statusState(row){
            let data
            this.refundstatusOptions.forEach(r=>{
                if (r.value==row.refundstatus){
                    data=r.label
                }
            })
            return data
        },
      hasRole(val){
        return this.hasPerm(val)
      },
      initForm() {//初始数据
        return {
          id : "", // 序号
          orderid : "", // 订单号
          userid : "", // 用户id
          useremail : "", // 用户邮箱
          spuid : "", // 商品id
          carid : "", // 车辆id
          ticketid : "", // 车票id
          refundstatus : "", // 退款状态
          paystatus : "", // 支付状态
          countrycode : "", // 国家代码
          countryname : "", // 国家名称
          paytype : "", // 支付方式
          refundamount : "", // 退款金额
          surplus : "", // 剩余金额
          createTime : "", // 创建时间
          updateTime : "", // 更新时间
          ext1 : "", // 扩展字段
          ext2 : "", // 扩展字段
          ext3 : "", // 扩展字段
          ext4 : "", // 扩展字段
        }
      },
      handleEdit(row) {
        console.log(row)
        this.form = row
        this.openDialog();
        this.title = "修改信息"
        this.type = "update"
      },
      handlePass(row){
          this.$confirmel('此操作将同意退款, 是否继续?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
          }).then(() => {
              this.passSumbit(row)
          }).catch(() => {
              MessageInfo("已取消");
          });
      },
      async passSumbit(row) {
        row.ticket = {}
          this.form=row
          let res = await orderrefundPass(this.form)
          if (res.status == "200") {
              MessageSuccess(res.msg)
          } else {
              MessageError(res.msg)
          }
          this.$refs.table.getList()
      },
      handleRefundClose(row){
            this.$confirmel('此操作将拒绝退款, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.closeSumbit(row)
            }).catch(() => {
                MessageInfo("已取消");
            });
      },
      async closeSumbit(row) {
          this.form = row
          let res = await orderrefundClose(this.form)
          if (res.status == "200") {
              MessageSuccess(res.msg)
          } else {
              MessageError(res.msg)
          }
          this.$refs.table.getList()
      },
      handleDelete(row) {
        this.$confirmel('此操作将永久删除该文件, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            this.operatorDeleteUser(row)
        }).catch(() => {
            MessageInfo("已取消删除");
        });
      },
      async operatorDeleteUser(row) {
        let res = await deleteOrderrefund(row.id)
        if (res.status == "200") {
          MessageSuccess(res.msg)
        } else {
          MessageError(res.msg)
        }
        this.$refs.table.getList()
      },
      handleInsert() {
        this.openDialog();
        this.title = "新增"
        this.type = "insert"
      },
      async Batch(type, ids) {
        if (type == "delete") {
          let res = await bacthDelOrderrefund(ids)
          if (res.status == "200") {
            MessageSuccess(res.msg)
          } else {
            MessageError(res.msg)
          }
        }
        this.$refs.table.getList()
      },
      openDialog() {
        this.dialogFormVisible = true
      },
      closeDialog() {
        this.dialogFormVisible = false
        this.form = this.initForm();
        this.$refs.ruleUser.clearValidate();
        this.$refs.table.getList()
      },
      async submitUser() {
        this.$refs.ruleUser.validate((valid) => {
          if (valid) {
            this.operatorUser()
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      async operatorUser() {
        let res;
        if (this.type == "insert") {
          res = await insertOrderrefund(this.form)
          if (res.status == "200") {
            MessageSuccess(res.msg)
            this.closeDialog()
          } else {
            MessageError(res.msg)
          }
        } else if (this.type == "update") {
          res = await updateOrderrefund(this.form)
          if (res.status == "200") {
            MessageSuccess(res.msg)
            this.closeDialog()
          } else {
            MessageError(res.msg)
          }
        }
        this.$refs.table.getList()
      },getUserInfo(){
        let deadline = sessionStorage.getItem('deadline');
        let userInfo = sessionStorage.getItem("userInfo");
        let token = sessionStorage.getItem('token');
        if(!deadline||!userInfo||!token) {
          this.removeInfo()
          MessageError("请先登录！")
          this.$router.push({ path: "/login" });
          return false
        }else{
          let now = new Date().getTime();
          if (deadline<now){
            this.removeInfo()
            MessageError("请先登录！")
            this.$router.push({ path: "/login" });
            return false
          }
        }
        return userInfo;
      },
      removeInfo(){
        sessionStorage.removeItem("userInfo");
        sessionStorage.removeItem("deadline");
        sessionStorage.removeItem("token");
      },
        async getGoodsSpuAllInfo() {
            let res = await getGoodsSpuAll()
            if (res.status == "200") {
                this.spuList=[]
                res.data.forEach(r=>{
                    let data={
                        value:r.id,
                        label:r.goodsname
                    }
                    this.spuList.push(data)
                })
                this.tableConfig.searchForm[0].options=this.spuList
            } else {
                MessageError(res.msg)
            }
        }
    },
    created() {
      let userInfo=this.getUserInfo()
      if (userInfo){
        this.userinfo=JSON.parse(userInfo)
        this.tableConfig.axiosConfig={
          headers: {
            'Authorization': 'X-KILOGOD-AUTHORIZATION '+sessionStorage.getItem('token')
          }
        }
        // if (!this.hasRole('orderrefund:update')&&!this.hasRole('orderrefund:delete')){
        //   this.tableConfig.hasOperation=false
        // }
          if(this.$route.query.id){
              this.tableConfig.url="orderrefund/list?orderid="+this.$route.query.id
          }
        this.getGoodsSpuAllInfo()
      }
    }
  }
</script>

<style scoped>

</style>
